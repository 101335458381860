.quiz-img{
    width:100%;
    max-width:600px;
    display: block;
    margin-left: auto;
    margin-right: auto;    
}

.quiz-question{

    width:100%;
    max-width:600px;
    display: block;
    margin-left: auto;
    margin-right: auto;    
}