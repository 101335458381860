@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }

    /* body {
        background-color: lightgray;
    } */
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
  }