.block-icon {
    position: relative;
    display: inline-flex;
  }
  
  .icon-tag-cross {
    position: absolute;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 22px !important;
    height: 22px !important;
  }

  .icon-tag-reverse {
    position: absolute;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 22px !important;
    height: 22px !important;
  }